// export const API_MEDIA_URL_1 = "https://ilserver.herokuapp.com/";
// export const API_MEDIA_URL_2 = "https://ilserver2.herokuapp.com/";
export const ROUTES = {
  home: "/",
  calendar: "/calendar",
  tournament_table: "/table",
  teams: "/teams",
  team: "/team/:teamName",
  race: "/race/:country",
  regulations: "/regulations",
  constructors_cup: "/constructorsCup",
  archive: "/archive",
};
export const CONTENTS = [
  {
    href: "basic-provisions",
    title: "Основные положения",
  },
  {
    href: "registration",
    title: "Регистрация и участие в соревнованиях",
  },
  {
    href: "general-rules",
    title: "Общие правила поведения",
  },
  {
    href: "rules-in-race",
    title: "Правила поведения во время квалификации и гонки",
  },
  {
    href: "classification",
    title: "Классификация",
  },
  {
    href: "score",
    title: "Начисление очков",
  },
  {
    href: "sc-and-claim",
    title: "СК и порядок подачи претензии",
  },
  {
    href: "punishments",
    title: "Нарушения и наказания за них",
  },
];
export const BASE_URL_1 = "https://ilserver.herokuapp.com/api/v1/";
export const BASE_TEAMS_URL_1 = BASE_URL_1 + "teams/";
export const BASE_PILOTS_URL_1 = BASE_URL_1 + "pilots/";
export const BASE_RACES_URL_1 = BASE_URL_1 + "races/";
export const BASE_URL_2 = "https://ilserver2.herokuapp.com/api/v1/";
export const BASE_TEAMS_URL_2 = BASE_URL_2 + "teams/";
export const BASE_PILOTS_URL_2 = BASE_URL_2 + "pilots/";
export const BASE_RACES_URL_2 = BASE_URL_2 + "races/";
export const LEAGUES = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};
export const POSITIONS = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};
export const CLASS_MAPPER = {
  [POSITIONS.FIRST]: "gold",
  [POSITIONS.SECOND]: "silver",
  [POSITIONS.THIRD]: "bronze",
};
export const IMG_TEAMS_URL = {
  ALFA_ROMEO: "/media/images/teams/alfa_romeo.jpg",
  ALPHA_TAURI: "/media/images/teams/alpha_tauri.jpg",
  ALPINE: "/media/images/teams/alpine.jpg",
  ASTON_MARTIN: "/media/images/teams/aston_martin.jpg",
  FERRARI: "/media/images/teams/ferrari.jpg",
  HAAS: "/media/images/teams/haas.jpg",
  MCLAREN: "/media/images/teams/mclaren.jpg",
  MERCEDES: "/media/images/teams/mercedes.jpg",
  RED_BULL: "/media/images/teams/red_bull.jpg",
  WILLIAMS: "/media/images/teams/williams.jpg",
};
export const IMG_TEAMS_MAPPER = {
  [IMG_TEAMS_URL.ALFA_ROMEO]: require("@/assets/img/teams/alfa_romeo.jpg"),
  [IMG_TEAMS_URL.ALPHA_TAURI]: require("@/assets/img/teams/alpha_tauri.jpg"),
  [IMG_TEAMS_URL.ALPINE]: require("@/assets/img/teams/alpine.jpg"),
  [IMG_TEAMS_URL.ASTON_MARTIN]: require("@/assets/img/teams/aston_martin.jpg"),
  [IMG_TEAMS_URL.FERRARI]: require("@/assets/img/teams/ferrari.jpg"),
  [IMG_TEAMS_URL.HAAS]: require("@/assets/img/teams/haas.jpg"),
  [IMG_TEAMS_URL.MCLAREN]: require("@/assets/img/teams/mclaren.jpg"),
  [IMG_TEAMS_URL.MERCEDES]: require("@/assets/img/teams/mercedes.jpg"),
  [IMG_TEAMS_URL.RED_BULL]: require("@/assets/img/teams/red_bull.jpg"),
  [IMG_TEAMS_URL.WILLIAMS]: require("@/assets/img/teams/williams.jpg"),
};
export const IMG_FLAGS_URL = {
  SPAIN: "/media/images/flags/spa.svg",
  AUSTRALIA: "/media/images/flags/au.svg",
  BELGIUM: "/media/images/flags/bel.svg",
  BAHRAIN: "/media/images/flags/bah.svg",
  NETHERLANDS: "/media/images/flags/net.svg",
  MONACO: "/media/images/flags/mon.svg",
  AUSTRIA: "/media/images/flags/aus.svg",
  CHINA: "/media/images/flags/chi.svg",
  AZERBAIJAN: "/media/images/flags/aze.svg",
  CANADA: "/media/images/flags/can.svg",
  SINGAPORE: "/media/images/flags/sin.svg",
  PORTUGAL: "/media/images/flags/por.svg",
  FRANCE: "/media/images/flags/fra.svg",
  BRAZIL: "/media/images/flags/bra.svg",
  GREATE_BRITAIN: "/media/images/flags/gb.svg",
  EMILIA_ROMAGNA: "/media/images/flags/ita.svg",
  RUSSIA: "/media/images/flags/rus.svg",
  USA: "/media/images/flags/usa.svg",
  JAPAN: "/media/images/flags/jap.svg",
  MEXICO: "/media/images/flags/mex.svg",
  SAUDI_ARABIA: "/media/images/flags/sa.svg",
  ITALY: "/media/images/flags/ita.svg",
};
export const IMG_FLAGS_WRAPPER = {
  [IMG_FLAGS_URL.SPAIN]: require("@/assets/img/flags/spa.svg"),
  [IMG_FLAGS_URL.AUSTRALIA]: require("@/assets/img/flags/au.svg"),
  [IMG_FLAGS_URL.BELGIUM]: require("@/assets/img/flags/bel.svg"),
  [IMG_FLAGS_URL.BAHRAIN]: require("@/assets/img/flags/bah.svg"),
  [IMG_FLAGS_URL.NETHERLANDS]: require("@/assets/img/flags/net.svg"),
  [IMG_FLAGS_URL.MONACO]: require("@/assets/img/flags/mon.svg"),
  [IMG_FLAGS_URL.AUSTRIA]: require("@/assets/img/flags/aus.svg"),
  [IMG_FLAGS_URL.CHINA]: require("@/assets/img/flags/chi.svg"),
  [IMG_FLAGS_URL.AZERBAIJAN]: require("@/assets/img/flags/aze.svg"),
  [IMG_FLAGS_URL.CANADA]: require("@/assets/img/flags/can.svg"),
  [IMG_FLAGS_URL.SINGAPORE]: require("@/assets/img/flags/sin.svg"),
  [IMG_FLAGS_URL.PORTUGAL]: require("@/assets/img/flags/por.svg"),
  [IMG_FLAGS_URL.FRANCE]: require("@/assets/img/flags/fra.svg"),
  [IMG_FLAGS_URL.BRAZIL]: require("@/assets/img/flags/bra.svg"),
  [IMG_FLAGS_URL.GREATE_BRITAIN]: require("@/assets/img/flags/gb.svg"),
  [IMG_FLAGS_URL.EMILIA_ROMAGNA]: require("@/assets/img/flags/ita.svg"),
  [IMG_FLAGS_URL.RUSSIA]: require("@/assets/img/flags/rus.svg"),
  [IMG_FLAGS_URL.USA]: require("@/assets/img/flags/usa.svg"),
  [IMG_FLAGS_URL.JAPAN]: require("@/assets/img/flags/jap.svg"),
  [IMG_FLAGS_URL.MEXICO]: require("@/assets/img/flags/mex.svg"),
  [IMG_FLAGS_URL.SAUDI_ARABIA]: require("@/assets/img/flags/sa.svg"),
  [IMG_FLAGS_URL.ITALY]: require("@/assets/img/flags/ita.svg"),
};
export const IMG_PILOTS_URL = {
  AKHMETOV: "/media/images/pilots/akhmetov.png",
  ALEXANDROV: "/media/images/pilots/nophoto.png",
  ALEXEEV: "/media/images/pilots/alexeev.png",
  ANISIN: "/media/images/pilots/nophoto.png",
  ANTONENKO: "/media/images/pilots/antonenko.png",
  BELOV: "/media/images/pilots/belov.png",
  BEZDENEZHNYY: "/media/images/pilots/nophoto.png",
  BLINOV: "/media/images/pilots/nophoto.png",
  BLOKHIN: "/media/images/pilots/nophoto.png",
  BONDAREV: "/media/images/pilots/bondarev.png",
  BORG: "/media/images/pilots/borg.png",
  BORTSOVA: "/media/images/pilots/bortsova.png",
  BOYKO: "/media/images/pilots/boyko.png",
  BRAGIN: "/media/images/pilots/bragin.png",
  BUBLYK: "/media/images/pilots/nophoto.png",
  BURYAK: "/media/images/pilots/nophoto.png",
  CHANDER: "/media/images/pilots/chander.png",
  DEGTYAREV: "/media/images/pilots/nophoto.png",
  DERBASOV: "/media/images/pilots/derbasov.png",
  DOLGOPYATOV: "/media/images/pilots/nophoto.png",
  DRANISHNIKOV: "/media/images/pilots/nophoto.png",
  DRONZIKOV: "/media/images/pilots/dronzikov.png",
  DZIS: "/media/images/pilots/dzis.png",
  GONTAR: "/media/images/pilots/nophoto.png",
  GRESHNOV: "/media/images/pilots/nophoto.png",
  HOVHANNISYAN: "/media/images/pilots/nophoto.png",
  IVANIN: "/media/images/pilots/nophoto.png",
  KHICHIN: "/media/images/pilots/nophoto.png",
  KHUDYAKOV: "/media/images/pilots/nophoto.png",
  KRYUKOV: "/media/images/pilots/kryukov.png",
  KUKHARENKO: "/media/images/pilots/nophoto.png",
  LANRIL: "/media/images/pilots/lanril.png",
  LAVROV: "/media/images/pilots/lavrov.png",
  LEVCHENKO: "/media/images/pilots/nophoto.png",
  LIPCHENKO: "/media/images/pilots/lipchenko.png",
  MACKOGONOV: "/media/images/pilots/nophoto.png",
  MAKARENKO: "/media/images/pilots/makarenko.png",
  MAKIN: "/media/images/pilots/makin.png",
  MARCHENKO: "/media/images/pilots/nophoto.png",
  MASLOV: "/media/images/pilots/nophoto.png",
  MEDZHAZHOK: "/media/images/pilots/nophoto.png",
  MILKIN: "/media/images/pilots/milkin.png",
  MINAEV: "/media/images/pilots/nophoto.png",
  MIRONOV: "/media/images/pilots/mironov.png",
  MOKHOVIKOV: "/media/images/pilots/nophoto.png",
  MTYURIN: "/media/images/pilots/nophoto.png",
  MUKHAMEDZHANOV: "/media/images/pilots/nophoto.png",
  MULDASHEV: "/media/images/pilots/nophoto.png",
  MYACHIN: "/media/images/pilots/myachin.png",
  NEVAZGNY: "/media/images/pilots/nevazgny.png",
  ORAZAEV: "/media/images/pilots/nophoto.png",
  OSADCHIY: "/media/images/pilots/osadchiy.png",
  PALAMARCHUK: "/media/images/pilots/nophoto.png",
  PAVLYUKEVICH: "/media/images/pilots/pavlyukevich.png",
  PERMINOW: "/media/images/pilots/perminow.png",
  POGODIN: "/media/images/pilots/pogodin.png",
  POLTORAK: "/media/images/pilots/poltorak.png",
  POLYAKOV: "/media/images/pilots/nophoto.png",
  RAND: "/media/images/pilots/nophoto.png",
  RAZUMOV: "/media/images/pilots/nophoto.png",
  RYABOV: "/media/images/pilots/nophoto.png",
  SALOMATIN: "/media/images/pilots/nophoto.png",
  SAVOSIN: "/media/images/pilots/savosin.png",
  SCHMIDT: "/media/images/pilots/nophoto.png",
  SEKUCHENKO: "/media/images/pilots/nophoto.png",
  SEREBRYAKOV: "/media/images/pilots/nophoto.png",
  SHAIMARDANOV: "/media/images/pilots/nophoto.png",
  SHIBANOV: "/media/images/pilots/shibanov.png",
  SHILOV: "/media/images/pilots/shilov.png",
  SHURAKOV: "/media/images/pilots/shurakov.png",
  SHUROV: "/media/images/pilots/nophoto.png",
  SKLYARENKO: "/media/images/pilots/sklyarenko.png",
  SOLOMKO: "/media/images/pilots/solomko.png",
  STAKANOVA: "/media/images/pilots/stakanova.png",
  STRELKOV: "/media/images/pilots/strelkov.png",
  TAIMENEV: "/media/images/pilots/nophoto.png",
  TARABUKIN: "/media/images/pilots/nophoto.png",
  TOMAZOV: "/media/images/pilots/tomazov.png",
  TSVETKOV: "/media/images/pilots/tsvetkov.png",
  TYMOSHENKO: "/media/images/pilots/tymoshenko.png",
  UGOZHAEV: "/media/images/pilots/nophoto.png",
  VLASOV: "/media/images/pilots/vlasov.png",
  YAKIMOW: "/media/images/pilots/nophoto.png",
  YAROVOI: "/media/images/pilots/nophoto.png",
  YUVCHENKO: "/media/images/pilots/nophoto.png",
  ZAGORODNYK: "/media/images/pilots/nophoto.png",
  ZAKHAROV: "/media/images/pilots/zakharov.png",
  ZELENKEVICH: "/media/images/pilots/nophoto.png",
  ZHUKOV: "/media/images/pilots/nophoto.png",
  ZHURKOVICH: "/media/images/pilots/zhurkovich.png",
};
export const IMG_PILOTS_WRAPPER = {
  [IMG_PILOTS_URL.AKHMETOV]: require("@/assets/img/pilots/akhmetov.png"),
  [IMG_PILOTS_URL.ALEXANDROV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.ALEXEEV]: require("@/assets/img/pilots/alexeev.png"),
  [IMG_PILOTS_URL.ANISIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.ANTONENKO]: require("@/assets/img/pilots/antonenko.png"),
  [IMG_PILOTS_URL.BELOV]: require("@/assets/img/pilots/belov.png"),
  [IMG_PILOTS_URL.BEZDENEZHNYY]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.BLINOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.BLOKHIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.BONDAREV]: require("@/assets/img/pilots/bondarev.png"),
  [IMG_PILOTS_URL.BORG]: require("@/assets/img/pilots/borg.png"),
  [IMG_PILOTS_URL.BORTSOVA]: require("@/assets/img/pilots/bortsova.png"),
  [IMG_PILOTS_URL.BOYKO]: require("@/assets/img/pilots/boyko.png"),
  [IMG_PILOTS_URL.BRAGIN]: require("@/assets/img/pilots/bragin.png"),
  [IMG_PILOTS_URL.BUBLYK]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.BURYAK]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.CHANDER]: require("@/assets/img/pilots/chander.png"),
  [IMG_PILOTS_URL.DEGTYAREV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.DERBASOV]: require("@/assets/img/pilots/derbasov.png"),
  [IMG_PILOTS_URL.DOLGOPYATOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.DRANISHNIKOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.DRONZIKOV]: require("@/assets/img/pilots/dronzikov.png"),
  [IMG_PILOTS_URL.DZIS]: require("@/assets/img/pilots/dzis.png"),
  [IMG_PILOTS_URL.GONTAR]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.GRESHNOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.HOVHANNISYAN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.IVANIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.KHICHIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.KHUDYAKOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.KRYUKOV]: require("@/assets/img/pilots/kryukov.png"),
  [IMG_PILOTS_URL.KUKHARENKO]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.LANRIL]: require("@/assets/img/pilots/lanril.png"),
  [IMG_PILOTS_URL.LAVROV]: require("@/assets/img/pilots/lavrov.png"),
  [IMG_PILOTS_URL.LEVCHENKO]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.LIPCHENKO]: require("@/assets/img/pilots/lipchenko.png"),
  [IMG_PILOTS_URL.MACKOGONOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MAKARENKO]: require("@/assets/img/pilots/makarenko.png"),
  [IMG_PILOTS_URL.MAKIN]: require("@/assets/img/pilots/makin.png"),
  [IMG_PILOTS_URL.MARCHENKO]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MASLOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MEDZHAZHOK]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MILKIN]: require("@/assets/img/pilots/milkin.png"),
  [IMG_PILOTS_URL.MINAEV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MIRONOV]: require("@/assets/img/pilots/mironov.png"),
  [IMG_PILOTS_URL.MOKHOVIKOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MTYURIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MUKHAMEDZHANOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MULDASHEV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.MYACHIN]: require("@/assets/img/pilots/myachin.png"),
  [IMG_PILOTS_URL.NEVAZGNY]: require("@/assets/img/pilots/nevazgny.png"),
  [IMG_PILOTS_URL.ORAZAEV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.OSADCHIY]: require("@/assets/img/pilots/osadchiy.png"),
  [IMG_PILOTS_URL.PALAMARCHUK]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.PAVLYUKEVICH]: require("@/assets/img/pilots/pavlyukevich.png"),
  [IMG_PILOTS_URL.PERMINOW]: require("@/assets/img/pilots/perminow.png"),
  [IMG_PILOTS_URL.POGODIN]: require("@/assets/img/pilots/pogodin.png"),
  [IMG_PILOTS_URL.POLTORAK]: require("@/assets/img/pilots/poltorak.png"),
  [IMG_PILOTS_URL.POLYAKOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.RAND]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.RAZUMOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.RYABOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SALOMATIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SAVOSIN]: require("@/assets/img/pilots/savosin.png"),
  [IMG_PILOTS_URL.SCHMIDT]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SEKUCHENKO]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SEREBRYAKOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SHAIMARDANOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SHIBANOV]: require("@/assets/img/pilots/shibanov.png"),
  [IMG_PILOTS_URL.SHILOV]: require("@/assets/img/pilots/shilov.png"),
  [IMG_PILOTS_URL.SHURAKOV]: require("@/assets/img/pilots/shurakov.png"),
  [IMG_PILOTS_URL.SHUROV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.SKLYARENKO]: require("@/assets/img/pilots/sklyarenko.png"),
  [IMG_PILOTS_URL.SOLOMKO]: require("@/assets/img/pilots/solomko.png"),
  [IMG_PILOTS_URL.STAKANOVA]: require("@/assets/img/pilots/stakanova.png"),
  [IMG_PILOTS_URL.STRELKOV]: require("@/assets/img/pilots/strelkov.png"),
  [IMG_PILOTS_URL.TAIMENEV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.TARABUKIN]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.TOMAZOV]: require("@/assets/img/pilots/tomazov.png"),
  [IMG_PILOTS_URL.TSVETKOV]: require("@/assets/img/pilots/tsvetkov.png"),
  [IMG_PILOTS_URL.TYMOSHENKO]: require("@/assets/img/pilots/tymoshenko.png"),
  [IMG_PILOTS_URL.UGOZHAEV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.VLASOV]: require("@/assets/img/pilots/vlasov.png"),
  [IMG_PILOTS_URL.YAKIMOW]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.YAROVOI]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.YUVCHENKO]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.ZAGORODNYK]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.ZAKHAROV]: require("@/assets/img/pilots/zakharov.png"),
  [IMG_PILOTS_URL.ZELENKEVICH]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.ZHUKOV]: require("@/assets/img/pilots/nophoto.png"),
  [IMG_PILOTS_URL.ZHURKOVICH]: require("@/assets/img/pilots/zhurkovich.png"),
};
