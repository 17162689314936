var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center il-menu"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"il-menu-btn",class:{
            'light-grey': _vm.color === 'lightGrey',
          },attrs:{"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Навигация ")])]}}])},[_c('v-list',{staticClass:"il-menu-list",staticStyle:{"background-color":"#1a202c"}},[_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/"}},[_vm._v(" Главная ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('a',{staticClass:"il-navigation-link",attrs:{"href":"https://vk.com/topic-185193200_48436167","target":"_blank"}},[_vm._v(" Регистрация ")])])],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/regulations"}},[_vm._v("Регламент")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/calendar"}},[_vm._v("Календарь")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":{
                name: 'TournamentTable',
                query: { league: _vm.$options.LEAGUES.FIRST },
              }}},[_vm._v("Турнирная таблица")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/constructorsCup"}},[_vm._v("Кубок конструкторов")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/teams"}},[_vm._v("Команды")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/archive"}},[_vm._v("Архив")])],1)],1)],1)],1)],1),_c('nav',{staticClass:"il-navigation",class:{
      'light-grey': _vm.color === 'lightGrey',
    }},[_c('router-link',{staticClass:"il-logo-link",attrs:{"to":"/"}},[_c('img',{staticClass:"il-logo-img",attrs:{"src":require("../assets/img/il_logo.jpg"),"alt":"logo"}}),_c('div',{staticClass:"il-logo-home"},[_vm._v("Главная")])]),_c('div',{staticClass:"il-navigation-links"},[_c('a',{staticClass:"il-navigation-link",attrs:{"href":"https://vk.com/topic-185193200_48038169","target":"_blank"}},[_vm._v(" Регистрация ")]),_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/regulations"}},[_vm._v("Регламент")]),_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/calendar"}},[_vm._v("Календарь")]),_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":{
          name: 'TournamentTable',
          query: { league: _vm.$options.LEAGUES.FIRST },
        }}},[_vm._v("Турнирная таблица")]),_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/constructorsCup"}},[_vm._v("Кубок конструкторов")]),_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/teams"}},[_vm._v("Команды")]),_c('router-link',{staticClass:"il-navigation-link",attrs:{"to":"/archive"}},[_vm._v("Архив")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }