<template>
  <thead>
    <tr v-once class="il-table-head">
      <th class="text-center">Позиция</th>
      <th class="text-center">Команда</th>
      <th class="text-center">Очки</th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: "ConstructorsTableHead",
};
</script>
