<template>
  <div class="il-regulations">
    <div class="il-regulations-contents">
      <h2 class="il-regulations-contents__title">Содержание</h2>
      <ol class="il-regulations-contents__list">
        <li v-for="(c, index) in $options.CONTENTS" :key="index">
          <a :href="'#' + c.href" class="il-regulations-contents__link">{{
            c.title
          }}</a>
        </li>
      </ol>
    </div>
    <section class="il-regulations-content">
      <ol>
        <li id="basic-provisions">
          <h3 class="il-regulations-content__title">1. Основные положения</h3>
          <ol>
            <li>
              «International League (PC)»– онлайн чемпионат по кольцевым
              автогонкам на формульных болидах класса «Formula 1» в игре F1 2021
              by Codemasters.
            </li>
            <li>
              Настоящим Регламентом Чемпионата (РЧ) регулируются правила
              участия, порядок организации и проведения чемпионата
              «International League (PC)» (1 и 2 лиг соответственно).
            </li>
            <li>
              Руководитель чемпионата и Судейский Комитет (СК) утверждает данный
              РЧ, Календарь Чемпионата, а также принимает решения по остальным
              вопросам, связанным с проведением чемпионата
            </li>
            <li>
              Чемпионат проходит в личном и командном первенствах: Личный Зачет
              (ЛЗ) и Кубок Конструкторов (КК).
            </li>
            <li>
              В чемпионате предусмотрен соответствующий вариант конфигурации
              сервера. Данный РЧ определяется руководителем турнира перед
              стартом сезона и представляет собой настройки игры во время
              заездов.
              <div class="il-regulations-content__subsection">
                <h4 class="il-regulations-content__subsection-title">
                  Настройки помощи:
                </h4>
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    Помощь в торможении –Выкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    АБС –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    ПБС – Полный;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Динамическая траектория –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    КПП –Автоматическая;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Помощь на пит-лэйн–Выкл (Вкл для Лиги 2 и Лиги 3);
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Помощь СРЭ – Выкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Помощь DRS – Выкл.
                  </li>
                </ul>
                <h4 class="il-regulations-content__subsection-title">
                  Настройки лобби IL1:<br />Начало Гран-При Лиги 1: Воскресенье,
                  20:00 (МСК)
                </h4>
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    Формат – Короткая квалификация (18 минут) + гонка 50%;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Характеристики болида – Равные;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Правило «ParcFerme» («Закрытый парк») –Вкл
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Столкновения –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Повреждения автомобиля – Стандартные;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Машина Безопасности –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Правила и флаги –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Строгость к срезанию углов –Строгие;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Прогревочный круг –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Старт гонки – Ручной;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Призрак – Выкл.
                  </li>
                </ul>
                <h4 class="il-regulations-content__subsection-title">
                  Настройки лобби IL2:<br />Начало Гран-При Лиги 2: Суббота,
                  20:00 (МСК)
                </h4>
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    Формат – короткая квалификация (18 минут) + гонка 50%;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Характеристики болида – Равные;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Правило «ParcFerme» («Закрытый парк») – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Столкновения – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Повреждения автомобиля – Стандартные;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Машина Безопасности –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Правила и флаги – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Строгость к срезанию углов – Строгие;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Прогревочный круг – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Старт гонки – Ручной;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Призрак – Вкл.
                  </li>
                </ul>
                <h4 class="il-regulations-content__subsection-title">
                  Настройки лобби IL3:<br />Начало Гран-При Лиги 3: Суббота,
                  18:00 (МСК)
                </h4>
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    Формат – короткая квалификация (18 минут) + гонка 50%;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Характеристики болида – Равные;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Правило «ParcFerme» («Закрытый парк») – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Столкновения – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Повреждения автомобиля – Стандартные;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Машина Безопасности –Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Правила и флаги – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Строгость к срезанию углов – Строгие;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Прогревочный круг – Вкл;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Старт гонки – Ручной;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Призрак – Вкл.
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p>Переходы</p>
              <p>
                Пилоты, занявшие 1 и 2 места, по окончанию сезона, в ЛЗ 2 Лиги,
                <span class="il-bold">автоматически перемещаются</span> в Лигу
                1.
              </p>
              <p>
                Пилоты, занявшие 19 и 20 места, по окончанию сезона, в ЛЗ 1
                Лиги,
                <span class="il-bold">автоматически перемещаются</span> в Лигу
                2.
              </p>
              <p>
                Пилоты, занявшие 3, 4 и 5 места, по окончанию сезона, в ЛЗ 2
                Лиги, а также пилоты, занявшие 16, 17 и 18 места, по окончанию
                сезона, в ЛЗ 1 Лиги, участвуют в тестовых заездах. Первые – за
                повышение, вторые – за то, чтобы остаться в 1 лиге.
              </p>
              <p>Тестовые заезды:</p>
              <p class="il-italic il-underline">
                - 3 трассы в режиме «Заезд на время».
              </p>
              <p>
                Администрация, путем случайного выбора трасс, предоставляет
                пилотам небольшой календарь для проведения «Заездов на время».
                Те, в свою очередь, обязаны за небольшой срок показать как можно
                лучшее время на этих трассах. Результаты будут занесены в
                отдельную таблицу.
              </p>
              <p class="il-italic il-underline">
                - 3 трассы в режиме «Лобби» (квалификация в 1 круг + 25% гонка).
              </p>
              <p>
                Администрация, путем случайного выбора трасс, определяет день, в
                который пилотам необходимо будет проехать 3 коротких Гран-При.
              </p>
              <p class="il-bold il-underline">Результаты тестов</p>
              <p>
                Определение показателей пилотов будет происходить следующим
                образом:
              </p>
              <table class="il-regulations-content__table">
                <tr>
                  <td>№</td>
                  <td>Трасса 1</td>
                  <td>Т2</td>
                  <td>Т3</td>
                  <td>Т4</td>
                  <td>Т5</td>
                  <td>Т6</td>
                  <td>Среднее значение (по месту)</td>
                </tr>
                <tr>
                  <td>Пилот А</td>
                  <td>1:24.933 (1 результат среди всех)</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                  <td>2</td>
                  <td>1</td>
                  <td>
                    (1+2+2+2+2+1) /6 =
                    <span class="il-bold">1,67 (2 ИТОГ)</span>
                  </td>
                </tr>
                <tr>
                  <td>Пилот Б</td>
                  <td>1:25.346 (2)</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>1</td>
                  <td>2</td>
                  <td><span class="il-bold">1,33 (1)</span></td>
                </tr>
              </table>
              <p>
                При равенстве средних значений итоговая позиция будет зависеть
                от количества побед, количества вторых мест и так далее.
              </p>
              <p>
                Первые 2 пилота*, которые набрали НАИМЕНЬШЕЕ среднее значение по
                4 итогам Тестов, получают путевку в 1 Лигу. Третьему пилоту
                предоставляется WildCard от Администрации Чемпионата. Остальные
                участники тестов участвуют во 2 лиге.
              </p>
              <p>
                *Количество пилотов может варьироваться. Если кто-то из 1 Лиги
                покидает Чемпионат, то в тестовые заезды добавляется еще 1 пилот
                со 2 Лиги, занявший 6 место и т.д.
              </p>
              <p class="il-bold il-underline">
                ПЕРЕХОДЫ ПО ТАКОМУ ЖЕ ПРИНЦИПУ ДЕСТВУЮТ МЕЖДУ ЛИГОЙ 2 и ЛИГОЙ 3.
              </p>
              <p class="il-underline">
                Администрация вправе оставить пилотов в текущих лигах, либо
                наоборот, переместить кого-либо при выяснении причин у самих
                пилотов.
              </p>
            </li>
            <li>
              <p class="il-underline">Ответственные лица:</p>
              <div class="il-regulations-content__subsection">
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    <p class="il-bold">Члены СК</p>
                    <p>
                      – вносят изменения в РЧ (по утверждению руководителя
                      чемпионата);
                    </p>
                    <p>
                      – рассматривают спорные моменты и гоночные инциденты в
                      гонке, назначают штрафы при подаче протеста пилота;
                    </p>
                    <p>
                      – предупреждают касательно действий пилотов на трассе
                      перед проведением Гран-При (ГП), по вопросам поведения на
                      трассе;
                    </p>
                  </li>
                  <li class="il-regulations-content__subsection-text il-bold">
                    Руководитель чемпионата;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    <p class="il-bold">Хосты:</p>
                    <p>
                      –отвечают за своевременное создание сервера для проведения
                      этапа чемпионата;
                    </p>
                    <p>
                      –в обязательном порядке делают скриншоты результатов
                      квалификации и гонки;
                    </p>
                    <p>–инициируют рестарт сессии при технических сбоях.</p>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <p>Рестарты (переносы) этапа:</p>
              <ul>
                <li class="il-regulations-content__subsection-text">
                  Пересоздание сессии запрещено. За исключением форс-мажорных
                  ситуаций (Например, падение сервера игры);
                </li>
                <li class="il-regulations-content__subsection-text">
                  Перенос или рестарт этапов производится исключительно при
                  решении 70% участников в голосовании, если ситуация, которая
                  привела к возможному рестарту, произошла в первой четверти
                  дистанции – 25% от общего числа кругов;
                </li>
                <li class="il-regulations-content__subsection-text">
                  Рестарт сессии может быть дан при массовой аварии, повлекшей
                  за собой сход 50% или более пилотов в первой четверти
                  дистанции - 25% от общего числа кругов. Администрация вправе
                  отменить рестарт по данной причине, если увидит момент
                  намеренного создания аварийных ситуаций с целью проведения
                  рестарта. Администрация также вправе не проводить рестарт
                  сессии без выяснения причины по данному подпункту;
                </li>
                <li class="il-regulations-content__subsection-text">
                  При падении сервера или технических сбоях игры вопрос о
                  возможном переносе или рестарте этапа обсуждается
                  Администрацией и выносится внутренним решением.
                </li>
              </ul>
              <p>Рестарт сессии проводится по правилам:</p>
              <ul>
                <li class="il-regulations-content__subsection-text">
                  Пилоты, не сошедшие в гонке, занимают места на стартовой
                  решетке, согласно их порядку в гонке на момент объявления
                  рестарта сессии. После располагаются пилоты, которые по каким
                  либо причинам сошли с дистанции в порядке их убывания – кто
                  раньше сошел, тот выше на решетке на момент объявления
                  рестарта сессии.
                </li>
                <li class="il-regulations-content__subsection-text">
                  Все пилоты имеют право стартовать на любом типе шин;
                </li>
                <li class="il-regulations-content__subsection-text">
                  Пилот, из-за которого возникла массовая авария, без претензий
                  и при 100% вине получает 3ШБ+30сек. к финишу на том этапе, в
                  котором произошел инцидент. Также Администрация вправе не
                  допустить к рестарту пилота (штраф – 3ШБ) или позволить ему
                  стартовать с последнего момента после рестарта сессии;
                </li>
                <li class="il-regulations-content__subsection-text">
                  Максимальное количество переносов (рестартов) – 1.
                </li>
              </ul>
            </li>
            <li>
              Распределение команд и болидов: все этапы проводятся на болидах с
              <span class="il-bold il-underline">равными</span>
              характеристиками.
            </li>
            <li>
              Могут участвовать только те люди, которые прошли регистрацию в
              группе и состоят в официальном сообществе Чемпионата.
            </li>
            <li>
              Пилоты могут по своему желанию выбрать любую команды из
              официальной Формулы 1 (Mercedes и т.д.), при условии, что место не
              занято другим пилотом.
            </li>
            <li>
              В случае вступления в чемпионат по ходу сезона участник должен
              занять любое свободное место в команде.
            </li>
            <li>
              Пилотам <span class="il-bold">запрещается</span> менять команду по
              ходу сезона.
            </li>
            <li>
              <span class="il-bold il-underline">Запуск ГП.</span> Сессию
              запускает хост в отведенное время запуска сессии. Администрация,
              на свое усмотрение, имеет право отложить старт сессии на 10-15
              минут.
            </li>
          </ol>
        </li>
        <li id="registration">
          <h3 class="il-regulations-content__title">
            2. Регистрация и участие в соревнованиях
          </h3>
          <ol>
            <li>
              <p>Подача заявки:</p>
              <p>
                – любой желающий может подать заявку на участие в чемпионате;
              </p>
              <p>
                – заявка на регистрацию проводится в специальном обсуждении в
                официальной группе Чемпионата;
              </p>
              <p>
                – в заявке на участие должны быть указаны
                <span class="il-bold"
                  >ФИ, дата рождения, ник в Steam (в формате I.Ivanov
                  (желательно реальные инициалы), команда, номер болида, на
                  каком устройстве пилот проводит гонки (руль, геймпад и т.д.),
                  а также скриншот профиля в игре</span
                >, строго в определенном формате;
              </p>
              <p>
                –
                <span class="il-bold il-underline"
                  >НИК ПО ХОДУ ЧЕМПИОНАТА МЕНЯТЬ ЗАПРЕЩЕНО!</span
                >
              </p>
            </li>
            <li>
              Запрещена подача заявки и участие в чемпионате одного человека под
              двумя или более различными именами
            </li>
            <li>
              Каждый регистрирующийся участник должен ознакомиться с РЧ, который
              расценивается как согласие и обязательство соблюдать все пункты
              РЧ.
            </li>
          </ol>
        </li>
        <li id="general-rules">
          <h3 class="il-regulations-content__title">
            3. Общие правила поведения
          </h3>
          <ol>
            <li>
              Правила поведения распространяются на все официальные заезды
              (квалификации и гонки).
            </li>
            <li>
              Всем участникам необходимо записывать квалификацию и гонку, для
              решения различных споров и итогов гонки. Участники Лиги 1
              записывают свой заезд в
              <span class="il-underline il-bold">ОБЯЗАТЕЛЬНОМ</span> порядке.
            </li>
            <li>
              Высказывать оскорбительную критику работы организаторов заездов,
              организаторов чемпионата и чемпионата в целом ЗАПРЕЩЕНО.
            </li>
            <li>
              Администрация Чемпионата принимает все решения, необходимые для
              проведения заезда.
            </li>
            <li>
              Все участники заезда обязаны выполнять все указания организатора
              заезда, предусмотренные данным РЧ
            </li>
            <li>
              <span class="il-bold il-underline">КАТЕГОРИЧЕСКИ</span>
              запрещается использование в Чемпионате нечестных приемов игры.
              Пилоты, которые будут в этом уличены, подлежат немедленной
              дисквалификации на весь Чемпионат, и перед администрацией портала
              будет поставлен вопрос о блокировке их доступа к порталу навсегда
              (БАН).
            </li>
            <li>
              <p class="il-bold il-italic">Запрещается:</p>
              <div class="il-regulations-content__subsection">
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    необоснованное экстренное торможение на прямой;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    блокировка сзади идущего участника путём пересечения
                    траектории 2 и более раз;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    использование багов или глюков игры в свою пользу;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    движение в противоположную сторону;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    пилоту, вылетевшему за пределы трассы, небезопасно
                    возвращаться на трассу;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    пересекать сплошную линию, отделяющую полосу выезда с
                    пит-лэйн от основной трассы, при выезде с пит-лэйн;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    намеренно повреждать свой или чужой болид;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    выходить из сессии игры, не находясь при этом на заезде на
                    пит-лэйн или в боксах;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    запрещено возвращаться на трассу с помощью «Авто-возврата»
                    через ESC;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    выходить из игры сразу по команде «Выход из игры».
                  </li>
                </ul>
              </div>
              <p class="il-bold il-italic">
                В случае неоднократного нарушения может последовать
                ДИСКВАЛИФИКАЦИЯ
              </p>
            </li>
            <li>
              <p>Флаги:</p>
              <div class="il-regulations-content__subsection">
                <ul>
                  <li class="il-regulations-content__subsection-text">
                    синие флаги: при виде синих флагов пилот обязан пропустить
                    более быстрый болид, идущий позади него;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    жёлтые флаги: появление жёлтых флагов на любом участке
                    трассы означает опасность на трассе в данном месте;
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    при оповещении о введении жёлтых флагов ВСЕ пилоты ОБЯЗАНЫ
                    сбросить скорость, при этом убедившись, что тем самым они не
                    создадут иную аварийную ситуацию.
                  </li>
                  <li class="il-regulations-content__subsection-text">
                    Обгоны во время действия режима жёлтых флагов
                    <span class="il-bold">ЗАПРЕЩЕНЫ.</span>
                  </li>
                </ul>
              </div>
            </li>
          </ol>
        </li>
        <li id="rules-in-race">
          <h3 class="il-regulations-content__title">
            4. Правила поведения во время квалификации и гонки:
          </h3>
          <h3 class="il-regulations-content__title">КВАЛИФИКАЦИЯ</h3>
          <ol>
            <li>
              В квалификации не должно возникать агрессивных или активных
              попыток обгона.
            </li>
            <li>
              Пилот, не находящийся на быстром круге, не должен создавать помехи
              пилотам, находящимся на нём (быстром круге).
            </li>
            <li>
              В случае, когда пилот, находящийся на быстром круге, догоняет
              другого пилота, также находящегося на быстром круге, более
              медленная машина НЕ обязана пропускать более быструю. Пилот,
              находящийся позади, должен учитывать это до начала быстрого круга
              и отпустить впереди идущий болид на достаточное расстояние во
              избежание попыток атаки и создания аварийных ситуаций.
            </li>
            <li>
              Если необходимо завершить сессию, то делать это необходимо строго
              в боксах.
            </li>
            <li>
              В квалификациях (во всех сегментах) запрещено намерено разбивать
              болид, а также перезаходить несколько раз в лобби, если нет для
              этого причин. (необходимо иметь запись-подтверждение технических
              неполадок, сетевых сбоев и т.д.)
            </li>
            <h3 class="il-regulations-content__title">ГОНКА</h3>
            <li>
              Во время прогревочного круга участники не должны совершать
              агрессивные манёвры по отношению к другим пилотам.
            </li>
            <li>
              Пилот имеет право выбора любой траектории движения в пределах
              трассы, скорости движения, передачи и точек торможения, разгона,
              если это не создаёт возможность аварийной ситуации или опасность
              для других болидов на трассе.
            </li>
            <li>
              Пилот обязан вести болид так, чтобы избегать столкновений и
              контактов с соперниками, приводящих к их развороту, вылету с
              трассы и/или повреждению их машин.
            </li>
            <li>
              Гонщик, находящийся сзади, видит ситуацию гораздо яснее, т. к.
              обзор вперед намного лучше, чем периферийное зрение и возможность
              обзора назад на скорости. Попытка атаки в узких и необгонных
              поворотах нежелательна т.к. может привести к столкновению. При
              попытке обгона на атакующего пилоталожится основная
              ответственность за совершение манёвра
            </li>
            <li>
              При совершении обгона любой ценой должна избегаться ситуация с
              контактом между болидами или иные действия, которые могут привести
              к опасной ситуации, повреждениям, либо сходу с дистанции.
            </li>
            <li>
              Пилот находится вне трассы, если все 4 колеса болида находятся за
              её пределами.
            </li>
            <li>
              Обгон за пределами трассы или с использованием пространства
              сервисной зоны запрещен.
            </li>
            <li>
              Гонщик, улучшивший свое положение в гонке при помощи такого
              маневра, должен немедленно позволить машине, которую он обогнал,
              занять свое прежнее место в гонке, даже при отсутствии данного
              требования от игры.
            </li>
            <li>Гонщик, не выполнивший данного требования, получает штраф.</li>
            <li>
              Смещения в зоне торможения разрешены, НО с учетом безопасности
              (см.п. 3.7).
            </li>
            <li>Обгон через призрак разрешен.</li>
            <li>
              Круговой должен без промедления сбросить скорость на первой же
              прямой после появления синих флагов и позволить более быстрому
              гонщику обогнать себя. В повороте или связке поворотов круговой не
              обязан уходить с траектории если это может вызвать опасность
              столкновения.
            </li>
            <li>
              Любые баги, глюки, и прочие ошибки игры, которые влекут за собой
              необоснованные штрафы или перестановки на финише могут быть
              обжалованы в случае подачи заявки от пострадавшего.
            </li>
            <li>
              В случае вылета из сессии пилот имеет право присоединиться
              обратно, если он не может этого сделать, и ошибка с вылетом не
              является массовой, то это будет считаться как технический сход.
            </li>
            <li>
              Выйти из игры можно только в момент заезда на пит-лейн путём
              нажатия 9 команды «Выйти из сессии». А уже затем путём нажатия
              команды «Выйти из игры».
            </li>
            <li>
              Во время режима «SafetyCar» («SC») пилот обязан соблюдать
              скоростные ограничения, не создавая при этом аварийных ситуаций.
              Ведение борьбы во время данного режима строго запрещено.
            </li>
            <li>
              Во время режима «SС» обгон впереди идущего болида запрещён
              (исключение см. п. 4.23).
            </li>
            <li>
              Запрещен намеренный вызов режима «SafetyCar». Администрация вправе
              потребовать видеозапись инцидента без оформленной претензии, если
              возникнут какие-то сомнения.
            </li>
            <li>
              При завершении режима «SC» пилот, который лидирует на данный
              момент, имеет право разогнаться только 1 РАЗ (без резких
              торможений на стартфинишной прямой, дабы не создать аварийных
              ситуаций), после того как машина безопасности окончательно ушла на
              пит-лейн и пропали желтые флаги (наказания предусмотрены в 8
              разделе).
            </li>
            <li>
              Если игра потребует вернуть позицию, то необходимо стараться
              соблюдать эти требования. Если штрафа избежать всё же не удалось,
              то он может быть обжалован в случае подачи претензии от
              пострадавшего.
            </li>
            <li>
              Болиды, находящиеся в круге, имеют право обогнать SС только в том
              случае, если позволит игра.
            </li>
            <li>
              При введении режима VirtualSС гонщик обязан сбросить скорость и
              соблюдать скоростное ограничение вплоть до возвращения гонки в
              боевой режим.
            </li>
            <li>
              При сбросе скорости необходимо убедиться, что тем самым вы не
              создадите аварийную ситуацию, если в непосредственной близости от
              вас находятся пилоты.
            </li>
            <li>
              Обгоны, создание любых аварийных ситуаций во время действия
              данного режима строго запрещено.
            </li>
            <li>
              Зоной пит-лейн считается въезд, ограниченный двумя белыми линиями.
            </li>
            <li>
              Во избежание опасной ситуации гонщик должен заранее занимать
              траекторию, которая будет способствовать его безопасному заезду в
              зону питлейн.
            </li>
            <li>Пересечение линии пит-лейна на выезде строго запрещено.</li>
          </ol>
        </li>
        <li id="classification">
          <h3 class="il-regulations-content__title">5. Классификация</h3>
          <ol>
            <li>
              Гонка считается завершенной, как только лидер преодолел
              предписанную дистанцию гонки и последний раз пересек линию финиша.
            </li>
            <li>
              Участники классифицируются в том порядке, в котором показано у
              хоста лиги, любые смены позиций, которые произвела игра сама,
              считаются верными. Пострадавший пилот может подать соответствующую
              претензию, если считает, что получил неверную смену позиции.
            </li>
          </ol>
        </li>
        <li id="score">
          <h3 class="il-regulations-content__title">6. Начисление очков</h3>
          <ol>
            <li>
              <p>Очки начисляются первым пятнадцати пилотам</p>
              <table class="il-regulations-content__table">
                <tr>
                  <td>МЕСТО</td>
                  <td>ОЧКИ</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>50</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>40</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>35</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>30</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>22</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>6</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td></td>
                </tr>
                <tr>
                  <td>17</td>
                  <td></td>
                </tr>
                <tr>
                  <td>18</td>
                  <td></td>
                </tr>
                <tr>
                  <td>19</td>
                  <td></td>
                </tr>
                <tr>
                  <td>20</td>
                  <td></td>
                </tr>
                <tr>
                  <td>ЛК</td>
                  <td>2</td>
                </tr>
              </table>
              <p>
                Резервные пилоты, которые заменяют кого-либо в Лиге 3, в свой
                Личный зачет получают полноценные очки – 50, 40, 35 и так далее,
                а для команды, в которой пилот заменяет основного, в Кубок
                Конструкторов зачисляются 50% очков – 25, 20, 17,5 и так далее.
              </p>
              <p>
                В Лиге 1 и Лиге 2 резервный пилот зачисляет как в Личный зачет,
                так и в Кубок конструкторов 100% очков.
              </p>
            </li>
            <li>
              Дополнительно зачисляется 2 очка, за
              <span class="il-bold il-underline">лучший круг</span>, при
              попадании в топ10 по итоговому протоколу.
            </li>
            <li>
              Начисление очков производится строго по итоговому протоколу гонки.
            </li>
            <li>
              В случае вынесения штрафов, либо иных решений, влияющих на
              протокол, производится пересчёт очков с учётом данных изменений.
            </li>
            <li>
              При равенстве результатов в личном зачете у двух и более пилотов —
              высшее место занимает пилот, занявший большее число высших мест
              (первых, затем вторых, третьих и т. д.).
            </li>
            <li>
              В случае полного совпадения показателей по позициям, определение
              позиций происходит по порядку лучшего финиша, т. е. пилот, который
              занял своё высшее место раньше высшего места другого и будет выше.
            </li>
            <li>
              Командные очки начисляются суммированием очков участников команды.
            </li>
            <li>
              Суммируются очки, которые были заработаны всеми участниками
              команды по ходу сезона.
            </li>
            <li>
              При равных очках, порядок определения позиции по итогам сезона
              аналогичен порядку в ЛЗ.
            </li>
          </ol>
        </li>
        <li id="sc-and-claim">
          <h3 class="il-regulations-content__title">
            7. СК и порядок подачи претензии
          </h3>
          <ol>
            <li>
              Непосредственными членами СК могут являться любые лица,
              утвержденные нынешним СК, ознакомленные с действующим РЧ.
            </li>
            <li>
              Все протесты подаются после окончания гонки в течение 24 часов
            </li>
            <li>
              Количество претензий на одного пилота, на каждый этап - по 3
            </li>
            <li>
              Все решения по протестам выносятся СК в течение 48 часов после
              завершения гонки.
            </li>
            <li>
              <p class="il-bold il-italic">
                Образец подачи претензии от одного участника на другого:
              </p>
              <p>1. Никнейм заявителя.</p>
              <p>2. Никнейм виновника.</p>
              <p>3. Указать ГП, в котором произошёл инцидент.</p>
              <p>4. Описание/причина претензии.</p>
              <p>
                5. Видеоряд (с указанным тайм-кодом или фрагмент), или несколько
                скриншотов с высоким разрешением.
              </p>
              <p>
                Претензия рассматривается только
                <span class="il-bold">при наличии видео или скриншотов,</span> и
                только в указанном формате.
              </p>
            </li>
            <li>
              При подаче апелляции непосредственно после подачи претензии,
              достаточно прикрепить видео к претензии или скриншоты по
              соответствующему эпизоду.
            </li>
            <li>
              При подаче апелляции по решению СК, будьте готовы предоставить
              членам судейской коллегии дополнительные материалы по данному
              эпизоду. Соответствующая просьба будет отражена в личном сообщении
              участнику эпизода на форуме.
            </li>
            <li>
              Убедительная просьба, воздерживаться от любых эмоциональных
              комментариев и мнений при подаче претензии. Приниматься к сведению
              они не будут. Кроме того, сам пострадавший, в случае некорректного
              поведения, может быть наказан СК.
            </li>
            <li>
              <span class="il-bold il-underline"
                >Штрафы, присужденные игрой,</span
              >
              в большинстве своем отмене не подлежат. Однако СК рассмотрит
              претензию со стороны потерпевшего за подобный необоснованный
              штраф.
            </li>
            <li>
              Решение о виновности или невиновности определяется исключительно
              членами СК.
            </li>
            <li>
              Апелляция на пересмотр решения СК, должна быть подана не позднее
              24 часов с момента вынесения штрафа пилоту. По истечению 24 часов
              после вынесения вердикта апелляции приниматься не будут.
            </li>
            <li>
              В качестве доказательств, члены СК могут использовать видеозаписи
              других гонщиков, где виден инцидент.
            </li>
            <li>
              Решение, вынесенное после апелляции, является окончательным и не
              подлежит изменению.
            </li>
            <li>
              При возникновении эпизодов, для которых отсутствует трактовка в
              РЧ, СК в праве принимать решения основываясь на правилах реальной
              Ф1, если соответствующие решения в ней применимы к реализации в
              данном эпизоде.
            </li>
          </ol>
        </li>
        <li id="punishments">
          <h3 class="il-regulations-content__title">
            8. Нарушения и наказания за них
          </h3>
          <ol>
            <li>
              Нарушение правил не попадающее под конкретный случай в зависимости
              от ситуации – 1 - 5 ШБ и/или 3-30сек.
            </li>
            <li>
              Намеренное повреждение своего болида - 2 ШБ и ДИСКВАЛИФИКАЦИЯ на
              след этап - решение принимает СК, если выявит это нарушение.
            </li>
            <li>
              Намеренное повреждение чужого болида - 5 ШБ и ДИСКВАЛИФИКАЦИЯ на
              след этап - решение принимает СК, если выявит это нарушение.
            </li>
            <li>Несоблюдение режима синих флагов – 2 ШБ.</li>
            <li>
              Блокировка сзади идущего участника путём многократного пересечения
              траектории движения (два смещения) на прямой –2 ШБ и 10 сек.
            </li>
            <li>
              Необоснованное экстренное торможение на прямой – 3 ШБ и 15 сек. к
              результату гонки.
            </li>
            <li>
              <p>
                Создание аварийной ситуации, вплоть до контакта, без нанесения
                повреждений имеет 5 степеней наказания, в зависимости от тяжести
                нарушения:
              </p>
              <p>-3 сек. к результату гонки;</p>
              <p>-5 сек. к результату гонки;</p>
              <p>- 1 ШБ и/или 10 сек. к результату гонки;</p>
              <p>- 2 ШБ и/или 20 сек. к результату гонки;</p>
              <p>- 3 ШБ и/или 30 сек. к результату гонки,</p>
              <p>
                или штраф -1 позиция на финише в случае отставания на круг или
                более.
              </p>
              <p>
                В случае если СК вынесли разные вердикты, то пилот получает
                наказание в виде среднего значения от общего числа решений.
              </p>
            </li>
            <li>
              <p>
                Создание аварийной ситуации, повлекшей за собой повреждения
                болида, вплоть до схода имеет 4 степени наказания, в зависимости
                от тяжести нарушения:
              </p>
              <p>- 2 ШБ и/или 10-20 сек. к результату гонки;</p>
              <p>- 3 ШБ и/или 30 сек. к результату гонки;</p>
              <p>- 4 ШБ и/или 40 сек. к результату гонки;</p>
              <p>- 5 ШБ и/или 50 сек. к результату гонки;</p>
              <p>- 6 ШБ и/или 60 сек. к результату гонки,</p>
              <p>
                или штраф -2 позиции на финише в случае отставания на круг или
                более.
              </p>
              <p>
                В случае если СК вынесли разные вердикты, то пилот получает
                наказание в виде среднего значения от общего числа решений.
              </p>
            </li>
            <li>Обгон под VSC или SC – 2ШБ+20 секунд.</li>
            <li>
              <p>
                Снятие штрафных секунд, после подачи жалоб на игру, либо на
                случаи, где по ошибке были выданы эти секунды при введении
                режима «SC» будут 14 ранжироваться по данным параметрам:
              </p>
              <p class="il-bold il-underline">ЕСЛИ ШТРАФ ОТБЫТ НА ПИТ-СТОПЕ:</p>
              <p>
                - последний в гонке Safety Car появился на 80-100% дистанции –
                не снимать штрафные секунды (ШС);
              </p>
              <p>
                - последний в гонке Safety Car появился на 60-79% дистанции –
                снять 40% ШС;
              </p>
              <p>
                - последний в гонке Safety Car появился на 45-59% дистанции –
                снять 50% ШС;
              </p>
              <p>
                - последний в гонке SafetyCar появился на 0-44% дистанции –
                снять <span class="il-bold il-italic">полностью</span> ШС.
              </p>
              <p class="il-bold il-underline">
                ЕСЛИ ШТРАФ <span style="color: #f52222">НЕ</span> ОТБЫТ НА
                ПИТ-СТОПЕ:
              </p>
              <p>
                - последний в гонке Safety Car появился на 85-100% дистанции –
                снять <span class="il-bold il-italic">полностью</span> ШС;
              </p>
              <p>
                - последний в гонке Safety Car появился на 60-84% дистанции –
                снять 75% ШС;
              </p>
              <p>
                - последний в гонке Safety Car появился на 40-59% дистанции –
                снять 50% ШС;
              </p>
              <p>
                - последний в гонке Safety Car появился на 25-39% дистанции –
                снять 30% ШС;
              </p>
              <p>
                - последний в гонке Safety Car появился на 0-24% дистанции –
                снять 15% ШС.
              </p>
            </li>
            <li>
              Пилот, намерено совершивший срезки в квалификации, без объяснения
              причин получает на первый раз 2ШБ, повторное нарушение – 2ШБ +
              пропуск квалификации в следующем Гран-При.
            </li>
            <li>
              Пилот, намеренно занявший чужой болид – 5 ШБ на первый раз. При
              повторном случае БАН до конца сезона.
            </li>
            <li>
              Участникам лиги ЗАПРЕЩЕНО нецензурно выражаться (мат) или же
              оскорблять других пилотов в чате, на форуме или непосредственно по
              голосовой связи во время гонки. Наказание – ДИСКВАЛИФИКАЦИЯ на
              следующий ГП, исключение из чемпионата, БАН - по усмотрению СК.
            </li>
            <li>
              Установлен лимит на получения штрафных баллов –7. (см п. 8.17.)
            </li>
            <li>
              В случае получения 7 ШБ, пилот получает ДИСКВАЛИФИКАЦИЮ на
              следующий ГП.
            </li>
            <li>
              Аннулирование 7 ШБ происходит после отбытия одной дисквалификации,
              полученной за ШБ.
            </li>
            <li>
              Если пилот многократно нарушил правила, что привело к набору
              большего количества баллов – то они сохраняются. Например, пилот
              набрал 10 ШБ – пропускает следующую гонку, 7 ШБ обнуляется, 3–
              остается. Если 14 ШБ - пропуск двух гонок подряд, обнуляется 14
              ШБ.
            </li>
            <li>
              Полученные ШБ за этап не обнуляются до конца чемпионата. Если
              пилот получил ШБ на последнем этапе сезона, то они переносятся на
              следующий сезон.
            </li>
            <li>
              Если пилот не вышел на старт и об этом не предупредил лично
              Руководителя Чемпионата хотя бы за ОДИН ЧАС до начала ГП, то он
              получает 2 ШБ и не принимает участие в квалификации на следующем
              этапе, т.е. остается в боксах. 3 подобных нарушения за сезон –
              ДИСКВАЛИФИКАЦИЯ до конца сезона.
            </li>
            <li>
              3 любых пропусков за сезон – ДИСКВАЛИФИКАЦИЯ до конца сезона. На
              усмотрение Администрации, пилот может остаться в Чемпионате, войдя
              в положение пилота.
            </li>
            <li>Предупреждения действуют строго в рамках сезона.</li>
            <li>
              Если гонщик был дисквалифицирован, и у него есть ещё какой-либо
              штраф, влияющий на позицию на стартовой решетке и прочее — он
              переносится на следующий этап.
            </li>
            <li>
              Все наказания присуждаются в зависимости от конкретного эпизода, и
              также могут представлять комбинации из нескольких наказаний.
              Итоговый вердикт зависит от степени виновности пилотов и
              определяется СК.
            </li>
            <li>
              За многократное нарушение правил в течение одного гран-при
              (основной гонки и/или спринта) возможно АННУЛИРОВАНИЕ результатов
              и/или ДИСКВАЛИФИКАЦИЯ вплоть до пожизненного БАНА.
            </li>
            <li>
              ПБ – Предупредительные баллы. За мелкие нарушения, выдавливания,
              подрезания, толкания и прочее, после подачи претензии со стороны
              пострадавшего, в той или иной ситуации, пилот будет получать ПБ,
              если СК 16 признает его виновным в том инциденте. Лимит получения
              этих баллов в течение сезона – 3. Если пилот набрал третий ПБ в
              текущем Гран-При, то получает самый минимальный штраф – 3сек. к
              финишному результату этой гонки.
            </li>
            <li>
              Выезд на быстрый круг после истечения основного времени ЗАПРЕЩЕН!
              Штраф за нарушение - 1шб.+пропуск квалификации на следующем этапе.
            </li>
            <li>
              Штраф за намеренный вызов режима «SafetyCar» - ДИСКВАЛИФИКАЦИЯ с
              этапа, на котором произошло нарушение + 3 ШБ.
            </li>
          </ol>
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
import { CONTENTS } from "@/const";

export default {
  name: "RegulationsContent",
  CONTENTS,
};
</script>

<style scoped>
.il-regulations {
  background-color: #242c41;
  margin-bottom: 40px;
}
.il-regulations-contents {
  padding-bottom: 50px;
  padding-top: 10px;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}
.il-regulations-contents__title {
  text-align: left;
  margin-left: 30px;
  padding: 15px 0;
}
.il-regulations-contents__list > li {
  text-align: left;
  margin-bottom: 15px;
  padding-left: 10px;
}
.il-regulations-contents__link {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s linear;
}
.il-regulations-contents__link:hover {
  color: #ce5a5a;
}
.il-regulations-content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 50px;
}
.il-regulations-content__title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
}
.il-regulations-content li {
  position: relative;
  list-style-type: none;
  margin-bottom: 10px;
}
.il-regulations-content li p {
  margin-bottom: 10px;
}
.il-bold {
  font-weight: 700;
}
.il-italic {
  font-style: italic;
}
.il-underline {
  text-decoration: underline;
}
.il-regulations-content > ol > li {
  margin-bottom: 50px;
}
.il-regulations-content ol {
  counter-reset: list1;
}
.il-regulations-content ol li:before {
  position: absolute;
  left: 340px;
  top: 4px;
  padding: 0 5px;
  text-align: center;
  font-weight: 700;
  counter-increment: list1;
  content: "";
}
.il-regulations-content ol ol {
  counter-reset: list2;
}
.il-regulations-content ol ol li:before {
  position: absolute;
  left: -50px;
  top: 0;
  counter-increment: list2;
  content: counter(list1) "." counter(list2) ". ";
}
.il-regulations-content__subsection {
  margin-top: 20px;
  padding-left: 40px;
}
.il-regulations-content__subsection-title {
  margin-bottom: 10px;
}
.il-regulations-content__subsection ul li {
  list-style-type: disc;
}
.il-regulations-content ol ol .il-regulations-content__subsection-text:before {
  content: "";
  counter-increment: none;
}
.il-regulations-content__table {
  text-align: center;
  margin: 0 auto 20px auto;
}
.il-regulations-content__table > tr > td {
  padding: 10px 20px;
  border: solid 1px #ffffff;
}
@media (max-width: 1263px) {
  .il-regulations-contents,
  .il-regulations-content {
    padding: 0 8px;
  }
}
@media (max-width: 959px) {
  .il-regulations-content__table > tr > td {
    padding: 4px;
    font-size: 14px;
  }
}
@media (max-width: 599px) {
  .il-regulations {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .il-regulations-content > ol > li {
    margin-bottom: 25px;
  }
  .il-regulations-contents__list > li {
    margin-bottom: 10px;
  }
  .il-regulations-content__title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .il-regulations-content__subsection {
    margin-top: 10px;
    padding-left: 20px;
  }
  .il-regulations-content__table > tr > td {
    padding: 0;
    font-size: 10px;
  }
}
@media (max-width: 319px) {
  .il-regulations-contents,
  .il-regulations-content {
    padding: 0 2px;
  }
  .il-regulations-content__table > tr > td {
    font-size: 8px;
  }
  .il-regulations-content__title {
    font-size: 16px;
  }
}
</style>
