var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"il-table-head"},[_c('th',{staticClass:"text-center"},[_vm._v("Поз.")]),_c('th',{staticClass:"text-center"},[_vm._v("Пилот")]),_c('th',{staticClass:"text-center"},[_vm._v("Команда")]),_c('th',{staticClass:"text-center"},[_vm._v("Очки")]),_vm._l((_vm.race),function(r,index){return _c('th',{key:index},[(!_vm.isArchive)?_c('router-link',{attrs:{"to":{
          name: 'Race',
          path: '/race' + r.country,
          params: { country: r.country, isArchive: _vm.isArchive },
          query: { league: _vm.leagueForTable },
        }}},[_c('v-img',{staticClass:"il-table-image",attrs:{"src":_vm.$options.getFlagImage(r.country_flag)}})],1):_c('router-link',{attrs:{"to":{
          name: 'Race',
          path: '/race' + r.country,
          params: {
            country: r.country,
            isArchive: _vm.isArchive,
            leagueForArchive: _vm.leagueForArchive,
          },
          // query: { league: leagueForArchive },
        }}},[_c('v-img',{staticClass:"il-table-image",attrs:{"src":_vm.$options.getFlagImage(r.country_flag)}})],1)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }